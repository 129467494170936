<template>
  <NuxtLink
    :to="blok?.url?.url ? blok.url.url : `/${locale}`"
    class="w-[254px] h-[240px] md:w-[436px] lg:w-[458px] md:h-[350px] flex-shrink-0"
  >
    <NuxtImg
      :loading="'lazy'"
      :src="OptimizeStoryBlokImage(blok.image.filename)"
      class="w-full rounded object-cover"
    />
    <div class="flex flex-col items-start mt-4 space-y-0.5">
      <span
        :class="
          blok.subtitle
            ? 'uppercase text-15 font-medium tracking-[0.15em] text-sanremo-gray'
            : 'font-semibold text-sm leading-5 md:text-lg tracking-wide'
        "
        >{{ blok.title }}</span
      >
      <span
        class="font-[250] text-sm text-sanremo-black leading-5 md:text-lg tracking-[0.02em] md:pr-5 md:pb-8"
        >{{ blok.subtitle }}</span
      >
    </div>
  </NuxtLink>
</template>

<script setup>
import { OptimizeStoryBlokImage } from '@/utils/helpers'

defineProps({
  blok: Object,
})

const { locale } = useI18n()
</script>
